import React, { Component } from "react"
import { Link } from "gatsby"
import { ContactForm, Layout } from "../../../components"
import { Helmet } from "react-helmet"

import {
  BlockImageTextHalf,
  Cubes,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/helcel/logo.png"
import Web from "../../../images/reference/helcel/helcel-web.png"
import Martin from "../../../images/reference/helcel/martin.jpg"
import Top from "../../../images/reference/helcel/top.png"
import Klaudie from "../../../images/team/mini/klaudie.jpg"
import Lukas from "../../../images/team/mini/lukas.jpg"
import Ads from "../../../images/reference/helcel/ads.png"
import Graf from "../../../images/reference/helcel/graf.png"

class Helcel extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    const changecolor = {
      color: `#000000`,
      textShadow: `0 0 20px #fff`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro HELCEL nábytek | igloonet
          </title>
          <meta
            name="description"
            content="Jak se nám povedlo snížit náklady za půl roku na reklamu a přitom zvýšit tržby ze spravovaných kanálů?"
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          extodkaz="//nabytek-helcel.cz/"
          heading="Převzali jsme kampaně a zlepšili jejich efektivitu o 19,52 %"
          changecolh={changecolor}
          odstavec="pohled na leden–červen 2018"
          changecol={changecolor}
        >
          <ReferenceBannerContent
            mainText="Rodinná společnost HELCEL Nábytek působí ve svém oboru už přes 25 let. Na e-shopu i v pěti prodejnách nabízí vše od sedacích souprav, obývacích stěn a postelí až po kuchyňské linky, dětské pokoje a bytové doplňky."
            textStyle={changecolor}
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Web}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Jak jsme postupovali</TitleH2>

              <p>
                Spolupráci jsme odstartovali na konci roku 2017 analýzou a
                zhodnocením dosavadních kampaní ve vyhledávání Google a Seznam,
                zaměřili jsme se přitom na jejich efektivitu a hledání dalšího
                potenciálu.
              </p>
              <p>
                Začátkem roku 2018 jsme navázali intenzivní prací na úpravě
                reklamních účtů. Kromě vyhledávačů jsme si posvítili také na
                výkonnostní kampaně na Facebooku. Za půl roku spolupráce jsme
                při rozsáhlé struktuře propagace dokázali{" "}
                <b>
                  snížit náklady na reklamu a přitom zvýšit tržby ze
                  spravovaných kanálů.
                </b>
              </p>
              <p>
                Období prvního půl roku spolupráce jsme pro zhodnocení zvolili z
                několika důvodů. Jde mj. o úsek nekřivený obecně velmi silnou
                předvánoční dobou či naopak slabším létem. Od srpna navíc
                vstupuje do hry také redesign webu znemožňující jednoznačné
                meziroční porovnání kampaní.
              </p>
              <Cubes
                data={[
                  {
                    description: "Snížení nákladů",
                    number: "-8,7%",
                  },
                  {
                    description: "Zvýšení tržeb",
                    number: "+13,4 %",
                  },
                  {
                    description: "Snížení PNO",
                    number: "-19,5 %",
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>

          <PaddingWrapper>
            <TitleH2>Cíle počátku spolupráce</TitleH2>
            <HalfWidthBlock>
              <p>
                Díky auditu PPC systémů jsme sice odhalili reálný potenciál na
                zlepšení výkonnosti kampaní, naším hlavním cílem ale bylo po
                domluvě s klientem <b>jasné a stabilní projektové řízení</b>.
                Bez něj si ostatně dosahování stanovených cílů a plynulé plnění
                plánu prací nedokážeme představit. Právě stabilní řízení v
                předchozí spolupráci chybělo.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>

          <PaddingWrapper>
            <h4>Dílčí cíle</h4>
            <HalfWidthBlock>
              <ul>
                <li>
                  Meziroční růst tržeb při <b>snížení PNO</b> u všech
                  spravovaných kanálů (počítáno pro last non-direct click
                  atribuci v Google Analytics).
                </li>
                <li>
                  <b>Zvýšení míry automatizace</b> tvorby a správy kampaní.
                </li>
              </ul>
            </HalfWidthBlock>
          </PaddingWrapper>

          <PaddingWrapper>
            <TitleH2>Stabilní projektové řízení</TitleH2>
            <HalfWidthBlock>
              <p>
                Pokud pomineme úvodní nastavování strategie, průběžnou úpravu
                vybraných taktik či obvyklou ad hoc komunikaci, pak gró
                projektového řízení zajišťujeme skrze:
              </p>

              <ul>
                <li>
                  <b>Zesílení dohledu nad celým projektem</b> – o HELCEL Nábytek
                  se od počátku stará stále stejná zkušená projektová vedoucí{" "}
                  <Link to="/klaudie">Klaudie</Link>, kontrolu řízení, stabilitu
                  a rozvoj pak posiluje i vedoucí týmu{" "}
                  <Link to="/lukas">Lukáš. </Link>
                  Oba v igloonetu navíc zastávají pozice garantů PPC systémů a
                  sociálních sítí.
                </li>
                <li>
                  <b>Pravidelné projektové schůzky</b> – kromě běžné každodenní
                  komunikace probíhá nad projektem i měsíční schůzka, kde řešíme
                  výsledky, určujeme priority a plánujeme další postup.
                  Pravidelně, byť s nižší frekvencí, se scházíme také s
                  klientem. Každá schůzka má svůj zápis a výstupem jsou mj.
                  úkoly pro konkrétní lidi.
                </li>
                <li>
                  <b>
                    Využití systému na řízení projektů Redmine <sup>1</sup>
                  </b>{" "}
                  – díky němu dokážeme efektivně řešit velké i drobné úkoly.
                  Nehrozí, že na nějaký zapomeneme, a máme přehled nad jejich
                  plněním. Do systému má přístup také klient.
                </li>
                <li>
                  <b>Systém checklistů</b> – pravidelné, opakující se práce jsou
                  součástí checklistů. Víme tedy, co už jsme zvládli, co je
                  potřeba udělat a co ideálně automatizovat.
                </li>
              </ul>

              <p>
                Jde o výčet několika hlavních postupů, které nám pomáhají (nejen
                tento) projekt řídit <b>přesně a ve vysokém standardu.</b>{" "}
                Zásadní je pro klienta udržet kontinuitu řízení, aby nemusel s
                různými lidmi opakovaně řešit stále stejné situace.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>

          <Medailon
            image={Martin}
            alt=""
            text="Požadavek na stabilní projektové řízení byl ze strany igloonetu splněn na 100 %. Považuji za
          velkou výhodu, že na projektu pracují stabilně stejní lidé, a vzájemně tak vždy víme, jaké jsou naše
          cíle. Velmi také oceňuji postřehy k možnému vylepšení celého projektu."
            name="Martin Helcel"
            position="jednatel"
          />

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Ads}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Google Ads a Seznam Sklik</TitleH2>

              <p>
                V kontextu cílů jsme se zaměřili zejména na výkonnostní kampaně
                ve vyhledávání a produktovou inzerci v Google Nákupech a Sklik
                Produktových inzerátech.
              </p>
              <p>
                Při zvyšování efektivity kampaní, resp. snižování PNO, si vždy{" "}
                <b>dáváme velký pozor na možný negativní vliv na transakce</b>,
                potažmo tržby (jak pro dané kanály, tak pro celý e-shop). Díky
                promyšleným změnám jsme však při snížení nákladů dokázali tržby
                z kanálů naopak zvýšit, a to o téměř 14 %.{" "}
                <b>Negativní vliv na e-shop se neobjevil, ba naopak</b>.
              </p>
              <p>
                Nutno podotknout, že přebírané kampaně nebyly v základu
                nastaveny špatně. Přesto jsme dokázali výrazně zvýšit jejich
                potenciál.
              </p>
              <Cubes
                data={[
                  {
                    description: "Snížení nákladů",
                    number: "-2,7 %",
                  },
                  {
                    description: "Zvýšení tržeb",
                    number: "+13,9 %",
                  },
                  {
                    description: "Snížení PNO",
                    number: "-14,6 %",
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>

          <PaddingWrapper>
            <h4>Za pozitivním zlepšením stojí zejména</h4>
            <HalfWidthBlock>
              <ul>
                <li>větší důraz na akvizici nových zákazníků</li>
                <li>využití automatického biddingu</li>
                <li>
                  rozšíření pokrytí inzerce o další relevantní klíčová slova
                </li>
                <li>zefektivnění nabízených CPC</li>
                <li>zefektivnění práce se segmenty uživatelů</li>
              </ul>
            </HalfWidthBlock>
          </PaddingWrapper>

          <Medailon
            image={Klaudie}
            alt=""
            text="HELCEL nábytek je klientem snů každého specialisty. Jeho silné zázemí a samotná
            specifika oboru nám poskytují obrovský prostor pro aplikaci nových přístupů, postupů,
            nápadů a automatizací, díky kterým jsme schopni kampaně dále posouvat."
            name="Klaudie Semelová"
            position="PPC specialistka, vedoucí projektu"
          />

          <PaddingWrapper>
            <HalfWidthBlock>
              <p>
                Přestože jsme náklady spíše snižovali a{" "}
                <b>konkurence v segmentu meziročně rostla</b>, dokázali jsme
                přivést o 26,5 % nových uživatelů více.
              </p>
              <p>
                Celkové PNO pro tyto kanály za zvolené období kleslo o 14,63 %
                při růstu tržeb o 13,92 %.
                <b>Vytyčeného dílčího cíle jsme tak dosáhli</b>.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Graf}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Facebook</TitleH2>

              <p>
                Facebookové kampaně nebyly součástí původního auditu, jejich
                analýza a následné úpravy probíhaly až od začátku ledna 2018.
              </p>
              <p>
                Zaměřili jsme se zejména na výkonnostní dynamické kampaně, které
                dokážou efektivně oslovovat uživatele, co v různé míře projevili
                zájem o produkty na e-shopu. Jejich síla je mimo jiné v
                personalizaci. Tento typ kampaní se používal i v předchozím
                období,
                <b>pod námi však prodělal velké změny</b>.
              </p>
              <p>
                Při tvorbě strategie a taktik jsme se opět zaměřili na snižování
                nákladů při udržení současného výkonu. Ideálně jsme ho chtěli
                ještě vylepšit.
              </p>
              <Cubes
                data={[
                  {
                    description: "Snížení nákladů",
                    number: "-44,9 %",
                  },
                  {
                    description: "Zvýšení tržeb",
                    number: "+9,0 %",
                  },
                  {
                    description: "Snížení PNO",
                    number: "-49,5 %",
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>

          <PaddingWrapper>
            <h4>Za pozitivním zlepšením stojí zejména</h4>
            <HalfWidthBlock>
              <ul>
                <li>
                  optimalizace kampaní na různé akce v závislosti na cestě
                  uživatele, nikoliv pouze na prokliky
                </li>
                <li>rozdělení struktury kampaní dle výkonu kategorií zboží</li>
                <li>
                  rozdělení struktury kampaní dle výkonnosti umístění reklam
                </li>
                <li>zapojení Instagramu</li>
                <li>
                  rozličnější využití štítků v reklamě, zpestření komunikace
                </li>
              </ul>
            </HalfWidthBlock>
          </PaddingWrapper>

          <Medailon
            image={Lukas}
            alt=""
            text="Volba našeho postupu vycházela zejména ze zkušeností a znalostí daných kanálů a jejich
            možností. Velký benefit vnímám zejména ve značném ušetření nákladů, které tak můžeme využít
            na další propagaci. Třeba právě na přivedení nových, potenciálních zákazníků."
            name="Lukáš Havlík"
            position="Vedoucí marketingového týmu"
          />

          <PaddingWrapper>
            <HalfWidthBlock>
              <p>
                Optimalizace na akce a rozdělení struktury dle kategorií přináší
                mimo jiné lepší schopnost reakce na poptávku během roku a
                přesnější kontrolu nad výkonem kampaní a náklady. Rozmělnění
                umístění otevírá prostor k pestřejší a zajímavější komunikaci s
                publiky.
              </p>
              <p>
                Z grafů je kromě obecného zlepšení patrná také{" "}
                <b>stabilizace výkonu celé struktury</b>. Výsledky mohly být
                teoreticky ještě lepší, z počátku roku jsme však chtěli udržet
                určitou kontinuitu kampaní, aby nedošlo k výpadku.
              </p>
              <p className="mb-5">
                Celkové PNO pro tyto kanály za zvolené období kleslo o 49,47 %
                při růstu tržeb o 8,98 %.{" "}
                <b>Vytyčeného dílčího cíle jsme tak dosáhli</b>.
              </p>
              <TitleH2>Závěr a další postup</TitleH2>
              <p>
                Splnili jsme a dále plníme hlavní cíl:{" "}
                <b>jasné a silně stabilní projektové řízení</b>. O projekt se od
                počátku stará stále stejná zkušená projektová vedoucí, kontrolu
                řízení, stabilitu a rozvoj projektu pak posiluje také vedoucí
                týmu. Oba zabezpečují kontinuitu projektu, pořádají pravidelné
                projektové schůzky, využívají mj. Redmine a checklisty.
              </p>
              <p>
                Díky správným a promyšleným zásahům a změnám v reklamních účtech
                jsme splnili i druhý, dílčí cíl.{" "}
                <b>
                  Celkové PNO za všechny zmíněné placené kanály kleslo za první
                  půlrok spolupráce o 19,52 % při růstu tržeb o 13,42 %.
                </b>
              </p>
              <h3 className="my-3 my-md-5">
                Kromě běžných automatizací nám se správou účtů pomáhá mj.
              </h3>
              <ul>
                <li>
                  vlastní nástroj na tvorbu reklam, díky kterému jsou textace
                  vždy aktuální
                </li>
                <li>smart bidding pro efektivní řízení rozpočtů</li>
                <li>denní hlídání anomálií a trendů</li>
                <li>denní hlídání spokojenosti zákazníků</li>
              </ul>
              <p>
                <b>Poslední dílčí cíl proto bereme také za splněný</b>. V
                automatizaci samozřejmě pokračujeme dál.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>

          <Medailon
            image={Martin}
            alt=""
            text="Lidé v igloonetu svědomitě plní všechny dohodnuté cíle,
            za což jim patří velké díky. Za nás zcela jistě nejlepší agentura,
             se kterou jsme kdy spolupracovali."
            name="Martin Helcel"
            position="Jednatel"
          />

          <PaddingWrapper>
            <HalfWidthBlock>
              <p>
                Po úvodní úpravě a správě primárně výkonnostních kampaních
                musíme v dalších krocích ještě víc přemýšlet nad
                <b>širšími souvislostmi</b>. Vyšší výnosy a ušetřené náklady
                reinvestujeme do dalších relevantních kanálů i publik v různých
                fázích nákupního procesu tak, abychom udrželi růst e-shopu a
                celé firmy.
              </p>
              <h3 className="my-3 my-md-4">
                Dále tedy s klientem spolupracujeme na
              </h3>
              <ul>
                <li>
                  správě nákupních galerií Favi a Biano, které postupně rostou
                  na síle,
                </li>
                <li>
                  broadových a brandových kampaních na nová publika s různým
                  potenciálem k nákupu (sociální a obsahové sítě, RTB…),
                </li>
                <li>
                  akvizici nových zákazníků pomocí obsahových sítí a Gmail
                  kampaní,
                </li>
                <li>
                  obecnějších remarektingových kampaních na chladnější publika a
                  retenci,
                </li>
                <li>propagaci kamenných prodejen,</li>
                <li>kampaních v zahraničí,</li>
                <li>zpřesňování analytiky.</li>
              </ul>
              <p>
                Tento celkový marketingový mix vede, jak nyní pozorujeme v
                datech, k{" "}
                <b>
                  meziročně velmi výrazným nárůstům tržeb a zisků v podzimním a
                  zimním období
                </b>
                , tedy v hlavní sezóně nábytkářů.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Helcel
